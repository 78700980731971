<template>
  <div class="container">
    <div class="main">
      <div class="box1">
        <div class="flexSb box1_main">
          <div class="box1_header">
            <div class="box1_left flexSb">
              <div class="item_left flexSt">
                <div class="student">
                  {{ StudentCurrentInfo.student_name.substr(-1) }}
                </div>
                <div class="">
                  <div class="flexSt student_in">
                    <span>姓名：</span>
                    <div class="">
                      {{ StudentCurrentInfo.student_name }}
                    </div>
                    <image
                      class="nan"
                      v-if="StudentCurrentInfo.student_gender == 1"
                      src="../../../assets//img/14.png"
                      mode=""
                    ></image>
                    <image
                      v-else
                      class="nv"
                      src="../../../assets//img/15.png"
                      mode=""
                    ></image>
                  </div>
                  <div class="right_1 student_in">
                    <span>班级：</span>
                    {{ Info.grade_name }}{{ Info.class_name }}
                  </div>
                  <div class="student_in">
                    <span>班号：</span>
                    {{ StudentCurrentInfo.student_no }}
                  </div>
                </div>
              </div>
              <div>
                <div class="left_list">
                  <span>课程名称：</span>
                  {{ Info.course_typeName }}
                </div>
                <div class="left_list">
                  <span>课程时间：</span>
                  {{ Info.showtime }}
                </div>
                <div class="left_list flexSt">
                  <span>运动项目：</span>
                  <div class="flexSt">
                    <div
                      class="sport"
                      v-for="(item, index) in Info.project_name.split(',')"
                      :key="index"
                    >
                      {{ item }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flexSb">
              <div class="timerep" ref="timeheart"></div>

              <div class="list_ri flexSt">
                <div
                  :style="{ background: item.bgcolor }"
                  class="litem"
                  v-for="(item, index) in list_type"
                  :key="index"
                >
                  <div class="title">
                    {{ item.title }}
                  </div>

                  <div class="num" :style="{ color: item.color }">
                    {{ item.num ? item.num : "--" }}<text>{{ item.dw }}</text>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="box1_right">
            <div class="box1_right_title">指标分析</div>
            <div class="table">
              <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeaderColor"
                :header-cell-class-name="cellClass"
                :cell-class-name="returnName"
              >
                <el-table-column
                  min-width="300"
                  label="运动指标"
                  align="center"
                >
                  <template slot-scope="scope">
                    <div v-if="scope.$index == 0">
                      <div class="flexSb prw_title">
                        <div>平均心率130-160次/分</div>
                        <div>
                          {{ list_type[0].num ? list_type[0].num : "--" }}次/分
                        </div>
                      </div>
                      <div class="flexSb">
                        <div class="pro">
                          <el-progress
                            :show-text="false"
                            :stroke-width="13"
                            :percentage="box1Info.avg_heart_ra"
                            :active-color="
                              box1Info.avg_heart_type_num != 1
                                ? '#EC6363'
                                : '#6DD27D'
                            "
                          ></el-progress>
                        </div>
                        <div
                          class="zc"
                          :class="{
                            pg:
                              box1Info.avg_heart_type == '偏低' ||
                              box1Info.avg_heart_type == '偏高',
                          }"
                        >
                          {{ box1Info.avg_heart_type }}
                        </div>
                      </div>
                    </div>
                    <div v-if="scope.$index == 1">
                      <div class="flexSb prw_title">
                        <div>最高心率130-160次/分</div>
                        <div>
                          {{ list_type[1].num ? list_type[1].num : "--" }}次/分
                        </div>
                      </div>
                      <div class="flexSb">
                        <div class="pro">
                          <el-progress
                            :show-text="false"
                            :stroke-width="13"
                            :active-color="
                              box1Info.max_heart_type_num != 1
                                ? '#EC6363'
                                : '#6DD27D'
                            "
                            :percentage="box1Info.max_heart_ra"
                          ></el-progress>
                        </div>
                        <div
                          class="zc"
                          :class="{
                            pg:
                              box1Info.max_heart_type == '偏低' ||
                              box1Info.max_heart_type == '偏高',
                          }"
                        >
                          {{ box1Info.max_heart_type }}
                        </div>
                      </div>
                    </div>
                    <div v-if="scope.$index == 2">
                      <div class="flexSb prw_title">
                        <div>运动强度50%-60%</div>
                        <div>
                          {{ box1Info.strength ? box1Info.strength : "--" }}%
                        </div>
                      </div>
                      <div class="flexSb">
                        <div class="pro">
                          <el-progress
                            :show-text="false"
                            :stroke-width="13"
                            :active-color="
                              box1Info.strength_type_num != 1
                                ? '#EC6363'
                                : '#6DD27D'
                            "
                            :percentage="box1Info.strength_ra"
                          ></el-progress>
                        </div>
                        <div
                          class="zc"
                          :class="{
                            pg:
                              box1Info.strength_type == '偏低' ||
                              box1Info.strength_type == '偏高',
                          }"
                        >
                          {{ box1Info.strength_type }}
                        </div>
                      </div>
                    </div>
                    <div v-if="scope.$index == 3">
                      <div class="flexSb prw_title">
                        <div>运动密度40%-50%</div>
                        <div>
                          {{ box1Info.density ? box1Info.density : "--" }}%
                        </div>
                      </div>
                      <div class="flexSb">
                        <div class="pro">
                          <el-progress
                            :show-text="false"
                            :stroke-width="13"
                            :percentage="box1Info.density_ra"
                            :active-color="
                              box1Info.density_type_num != 1
                                ? '#EC6363'
                                : '#6DD27D'
                            "
                          ></el-progress>
                        </div>
                        <div
                          class="zc"
                          :class="{
                            pg:
                              box1Info.density_type == '偏低' ||
                              box1Info.density_type == '偏高',
                          }"
                        >
                          {{ box1Info.density_type }}
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column width="120" label="合格情况" align="center">
                  <template slot-scope="scope">
                    <div class="flexSc">
                      <div class="num">合格</div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <!-- <div class="jl">
          <span>分析结论：xxxx</span>
        </div> -->
      </div>

      <div class="box2">
        <div class="box2_title">平均心率曲线</div>
        <div class="options_a_a">
          <!-- :opts="opts" -->
          <div class="averageheart" ref="averageheart"></div>
        </div>
      </div>

      <div class="box3">
        <div class="box2_title flexSb">
          <div>历史课堂数据</div>
        </div>
        <el-table
          ref="multipleTable"
          :data="listTbale"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeaderColor"
          :header-cell-class-name="cellClass"
          :cell-class-name="returnName"
        >
          <el-table-column
            min-width="120"
            prop="prepare_name"
            label="课程名称"
            align="center"
          >
          </el-table-column>
          <el-table-column min-width="120" label="运动时长" align="center">
            <template slot-scope="scope"
              >{{ scope.row.time ? scope.row.time : "--" }}
            </template>
          </el-table-column>
          <el-table-column label="平均心率" width="120" align="center">
            <template slot-scope="scope"
              >{{ scope.row.avg_heart ? scope.row.avg_heart : "--" }}(次/分)
            </template>
          </el-table-column>

          <el-table-column label="最高心率" width="126" align="center">
            <template slot-scope="scope">
              {{
                scope.row.max_heart ? scope.row.max_heart : "--"
              }}(次/分)</template
            >
          </el-table-column>
          <el-table-column label="靶心率运动时间" width="146" align="center">
            <template slot-scope="scope">
              {{
                scope.row.target_heart ? scope.row.target_heart : "--"
              }}</template
            >
          </el-table-column>
          <el-table-column label="心率≥120时间" width="146" align="center">
            <template slot-scope="scope">
              {{ scope.row.heart_than_120 ? scope.row.heart_than_120 : "--" }}
            </template>
          </el-table-column>

          <el-table-column label="运动强度" min-width="94" align="center">
            <template slot-scope="scope">
              {{ scope.row.strength ? scope.row.strength : "--" }}
            </template>
          </el-table-column>
          <el-table-column label="运动密度" min-width="94" align="center">
            <template slot-scope="scope">
              {{ scope.row.density ? scope.row.density : "--" }}
            </template>
          </el-table-column>
          <el-table-column label="课程时间" min-width="140" align="center">
            <template slot-scope="scope">
              {{
                scope.row.class_start_time ? scope.row.class_start_time : "--"
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="出勤情况"
            fixed="right"
            min-width="94"
            align="center"
          >
            <template slot-scope="scope">
              <div>
                <el-tag
                  type="success"
                  v-if="scope.row.attendance_status == 1"
                  class="cq"
                >
                  出勤
                </el-tag>
                <el-tag type="danger" v-else class="wcq"> 未出勤 </el-tag>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { getUser } from "@/utils/auth";
import { timeheart, singleaverageheart } from "./enums";
import {
  studentReport,
  studentclassGraph,
  reportscheduleStudent,
} from "@/api/classroon/practice.js";
export default {
  data() {
    return {
      listTbale: [],
      StudentCurrentInfo: {
        student_name: "",
      },
      Info: {
        grade_name: "",
        class_name: "",
        student_count: "",
        attendance_count: "",
        course_typeName: "",
        showtime: "",
        project_name: "",
      },
      tableData3: [],
      tableData: [1, 2, 3, 4],
      timeheart: timeheart,
      list_type: [
        {
          title: "平均心率",
          num: 0,
          dw: "次/分",
          color: "#57C365",
          bgcolor: "#F2FCF4",
        },
        {
          title: "平均最高心率",
          num: 0,
          dw: "次/分",
          color: "#F16666",
          bgcolor: "#FEF7F7",
        },
        {
          title: "心率≥120时间",
          num: 0,
          color: "#58B0EB",
          bgcolor: "#F6FAFD",
        },
        {
          title: "运动密度",
          num: 0,
          color: "#EEB126",
          bgcolor: "#FFFAEF",
          dw: "%",
        },
        {
          title: "运动强度",
          num: 0,
          color: "#5AD2BE",
          bgcolor: "#F1FAF9",
          dw: "%",
        },
        {
          title: "平均消耗",
          num: 0,
          dw: "千卡",
          color: "#F8885B",
          bgcolor: "#FDF6F3",
        },
      ],
      averageheart: singleaverageheart,
      box1Info: {
        class_num: 0,
        attendance_um: 0,
      },
      tableData2: [1],
    };
  },
  mounted() {
    this.$nextTick(() => {
      // this.handleInittimeheart();
    });

    if (this.$route.query.val) {
      this.teacher_id = getUser().id;
      this.Info = JSON.parse(this.$route.query.val);
      let val = this.Info;
      console.log(val);

      this.Info.showtime = val.date + val.time;
      this.Info.course_typeName =
        val.course_type == 1 ? "常规体育课" : "大课间";
      this.StudentCurrentInfo = JSON.parse(this.$route.query.student);
      this.handleGetstudentReport();
      this.handlestudentclassGraph();
      this.handlescheduleStudent();
      console.log(this.StudentCurrentInfo);
    }
  },
  methods: {
    async handlescheduleStudent() {
      let data = {
        class_id: this.Info.class_id,
        student_id: this.StudentCurrentInfo.student_id,
        per_page: 99999,
        page: 1,
      };
      await reportscheduleStudent(data).then((res) => {
        this.listTbale = res.data.data;
      });
    },
    async handlestudentclassGraph() {
      let data = {
        id: this.Info.id,
        student_id: this.StudentCurrentInfo.student_id,
      };
      await studentclassGraph(data).then((res) => {
        let time = res.data.time;
        let class_list = res.data.class_data;
        let student = res.data.student;

        this.averageheart.xAxis.data = time;
        this.averageheart.series[0].data = class_list;
        this.averageheart.series[1].data = student;

        this.handleInitaverageheart();
        // this.getServerData(time, class_list, student);
      });
    },
    async handleGetstudentReport() {
      let data = {
        id: this.Info.id,
        class_id: this.Info.class_id,
        student_id: this.StudentCurrentInfo.student_id,
      };
      await studentReport(data).then((res) => {
        console.log(res);
        let val = res.data[0];
        this.box1Info = res.data[0];
        this.list_type[0].num = val.avg_heart;
        this.list_type[1].num = val.max_heart;
        this.list_type[2].num = val.heart_than_120;
        this.list_type[3].num = val.density;
        this.list_type[4].num = val.strength;
        this.project_name = val.project_name;
        this.timeheart.series[0].data[0].name =
          this.box1Info.target_heart + "分钟";
        this.handleInittimeheart();
      });
    },
    handleGetShow() {
      console.log(222);
    },
    handleSeeSingle() {
      console.log(111);
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      return {
        fontSize: "13px",
        color: "rgb(0,12,22)",
        paddingTop: "8px",
        paddingBottom: "8px",
        backgroundColor: "#F2F7FF",
      };
    },
    cellClass() {
      return "disableSelection";
    },
    returnName(obj) {
      if (obj.columnIndex == 0) {
        return "firstCell";
      }
    },

    handleInitaverageheart() {
      let chartDom = this.$refs.averageheart;
      let myChart = echarts.getInstanceByDom(chartDom);
      if (myChart == null) {
        //不存在，进行初始化操作
        myChart = echarts.init(chartDom);
      }
      myChart.setOption(this.averageheart);
    },
    handleInittimeheart() {
      let chartDom = this.$refs.timeheart;
      let myChart = echarts.getInstanceByDom(chartDom);
      if (myChart == null) {
        //不存在，进行初始化操作
        myChart = echarts.init(chartDom);
      }
      myChart.setOption(this.timeheart);
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: stretch;
  padding: 20px;
  box-sizing: border-box;
  height: 100%;
}
.jl {
  padding: 25px;
  box-sizing: border-box;
  color: rgba(16, 16, 16, 1);
  font-size: 16px;
  span {
    font-weight: 600;
  }
}
.main {
  flex: 1;
  .box1 {
    width: 100%;
    border-radius: 20px;
    background-color: #fff;
    .student {
      width: 80px;
      height: 80px;
      background: #f2f7ff;
      text-align: center;
      line-height: 80px;
      font-size: 40px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #444444;
      margin-right: 17px;
    }
    .box1_main {
      width: 100%;
      align-items: stretch;
    }
    .box1_header {
      align-items: stretch;
      width: 50%;
      .box1_left {
        padding: 16px 27px 13px 55px;
        box-sizing: border-box;
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(230, 230, 230, 1);
        .grade {
          color: rgba(16, 16, 16, 1);
          font-size: 28px;
        }
        .item_left {
          color: rgba(115, 115, 115, 1);
          font-size: 16px;
        }
        .right_1 {
          margin: 6px 0;
        }
        .student_in {
          color: rgba(16, 16, 16, 1);
          font-size: 16px;
          span {
            font-weight: 600;
          }
        }
        .left_list {
          margin-bottom: 6px;
          color: rgba(16, 16, 16, 1);
          font-size: 16px;
          span {
            font-weight: 600;
          }
          .sport {
            height: 23px;
            line-height: 23px;
            border-radius: 5px;
            background-color: rgba(255, 255, 255, 1);
            color: rgba(146, 146, 146, 1);
            font-size: 14px;
            text-align: center;
            font-family: Roboto;
            padding: 0 5px;
            box-sizing: border-box;
            border: 1px solid rgba(146, 146, 146, 1);
            margin-right: 6px;
          }
        }
      }
      .timerep {
        width: 127px;
        height: 119px;
      }
      .list_ri {
        margin-top: 18px;
        flex: 1;
        flex-wrap: wrap;
        .litem {
          width: 31%;
          height: 70px;
          border-radius: 10px;
          margin-bottom: 5px;
          margin-left: 5px;
          text-align: center;
          padding-top: 12px;
          box-sizing: border-box;

          .title {
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #444444;
          }

          .num {
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;

            text {
              font-size: 16px;
            }
          }
        }
      }
    }
    .box1_right {
      flex: 1;
      padding: 16px 0px 11px 13px;
      border-left: 1px solid rgba(230, 230, 230, 1);
      border-bottom: 1px solid rgba(230, 230, 230, 1);
      box-sizing: border-box;
      .box1_right_title {
        color: rgba(16, 16, 16, 1);
        font-size: 20px;
      }
      .table {
        margin-top: 9px;
        .prw_title {
          width: 91%;
          color: rgba(94, 94, 94, 1);
          font-size: 14px;
        }
        .pro {
          width: 95%;
        }
        .num {
          margin-top: 18px;
          cursor: pointer;
          span {
            text-decoration: underline;
          }
        }
        /deep/ .el-table td.el-table__cell,
        /deep/.el-table th.el-table__cell.is-leaf {
          border-bottom: 0;
        }
      }
      /deep/.el-table::before {
        height: 0;
      }
      /deep/.el-table .el-table__cell {
        padding: 0;
      }
    }
  }
  .box2 {
    padding: 13px 28px;
    box-sizing: border-box;
    height: 320px;
    line-height: 20px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 1);
    color: rgba(16, 16, 16, 1);
    font-size: 14px;
    margin: 11px 0;
    color: rgba(16, 16, 16, 1);
    font-size: 20px;
    font-weight: 600;
    .averageheart {
      width: 100%;
      height: 280px;
    }
  }
  .box3 {
    padding: 13px 28px;
    box-sizing: border-box;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 1);
    .box2_title {
      color: rgba(16, 16, 16, 1);
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 16px;
    }
    .aaaaaa {
      font-size: 16px;
    }
    ::-webkit-scrollbar {
      width: 10px !important;
      height: 10px !important;
      // background-color: #ccc !important;
    }
    ::-webkit-scrollbar-track {
      // background-color: #bbbbbb;
    }
    /* 滚动条上的滚动滑块 */
    ::-webkit-scrollbar-thumb {
      background-color: #ccc;
    }
  }
  .zc {
    color: #4cda63;
  }

  .pg {
    color: #ff3636;
  }
}
</style>
